.login-form {
    width: 340px;
    margin: 50px auto;
}
.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    -webkit-box-shadow: 0px 0px 5px 3px rgba(204,204,204,0.75);
    -moz-box-shadow: 0px 0px 5px 3px rgba(204,204,204,0.75);
    box-shadow: 0px 0px 5px 3px rgba(204,204,204,0.75);
    padding: 30px;
}
.login-form h2 {
    margin: 0 0 15px;
}
.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}
.btn {        
    font-size: 15px;
    font-weight: bold;
}